import React from 'react';

const WarningIcon = () => (
  <svg width="17" height="12" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.27273 8.84211H7.72727V5.15789H9.27273M9.27273 11.7895H7.72727V10.3158H9.27273M0 14H17L8.5 0L0 14Z"
      fill="#8C8C8C" />
  </svg>
);

export default WarningIcon;
