const loadFontFamily = (families: Array<string>, callback?: (success: boolean) => void) => {
    if (typeof window !== 'undefined') {
      const WebFont = require('webfontloader');
      // @TODO: Handle loading custom fonts
      WebFont.load({
        google: {
          families
        },
        active: () => callback && callback(true),
        inactive: () => callback && callback(false)
      });
    }
  };
  
  export {
    loadFontFamily
  };
  