import React from 'react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import WarningIcon from './WarningIcon';

const StyledTestLink = styled(Box)({
  display: 'inline-block',
  padding: '8px',
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: 1.75,
  top: 8,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  color: '#8C8C8C',
  backgroundColor: '#FFFC00',
  position: 'absolute',
  right: '16px',
});

export const DoNotShareAlert = () => {
  return (
    <StyledTestLink>
      <Typography
        gutterBottom={false}
        style={{
          lineHeight: 1,
          fontWeight: 800,
          fontSize: '14px',
        }}
      >
        <WarningIcon />
        TEST LINK
      </Typography>
      <Typography
        gutterBottom={false}
        style={{
          lineHeight: 1,
          fontWeight: 800,
          fontSize: '12px',
        }}
      >
        DO NOT SHARE
      </Typography>
    </StyledTestLink>
  );
};

export default DoNotShareAlert;
