enum ERROR_TYPES {
  Error,
  Warn,
  Debug,
};

const logger = (message: string, errorType: ERROR_TYPES = ERROR_TYPES.Error, context: any = undefined): void => {
  switch (errorType) {
    case ERROR_TYPES.Warn: {
      console.warn(message, context);
      break;
    }
    case ERROR_TYPES.Debug: {
      console.log(message, context);
      break;
    }
    default: {
      console.error(message, context);
      break;
    }
  }
};

export { 
  logger,
  ERROR_TYPES
};
